import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import { PromotedStore } from '../../../types/product.types';

import { Button } from '../../button/Button';

import { polishLanguage } from '@helpers/translation.helper';

import s from './PromotedStoresDesktop.module.scss';

interface IPromotedStoresDesktop {
  promotedStores: PromotedStore[];
}

const PromotedStoresDesktop = ({ promotedStores }: IPromotedStoresDesktop) => {
  const intl = useIntl();
  const sendDataToGTM = useGTMDispatch();

  const returnPromotedStores = () => {
    return promotedStores.map((store, index) => {
        if (store.displaystoreurladdress && store.productlink && store.storelogo && store.storeurladdress) {
          return (
            <div className={s.promotedStoresDesktop__row} key={index}>
              <div className={`${s.promotedStoresDesktop__leftSide} ${s.promotedStoresDesktop__logo}`}>
                <a href={store.productlink} rel={'nofollow noopener'} target={'_blank'} onClick={() => {
                  sendDataToGTM({
                    'event': 'promoted-store',
                    'event_category': 'logo click',
                    'event_label': `${store.displaystoreurladdress}`,
                    'event_value': index + 1,
                    'event_language': intl.locale,
                  });
                }}>
                  <GatsbyImage
                    className={s.promotedStoresDesktop__img}
                    image={getImage(store.storelogo.localFile)!}
                    alt={store.storelogo.altText}
                  />
                </a>
              </div>
              <div className={`${s.promotedStoresDesktop__leftSide} ${s.promotedStoresDesktop__store}`} onClick={() => {
                sendDataToGTM({
                  'event': 'promoted-store',
                  'event_category': 'link click',
                  'event_label': `${store.displaystoreurladdress}`,
                  'event_value': index + 1,
                  'event_language': intl.locale,
                });
              }}>
                <a href={store.storeurladdress} hrefLang={intl.locale} target={'_blank'} rel={'nofollow noopener'}
                   className={s.promotedStoresDesktop__text}
                   aria-label={store.displaystoreurladdress}>{store.displaystoreurladdress} </a>
              </div>
              <div className={`${s.promotedStoresDesktop__rightSide} ${s.promotedStoresDesktop__warranty}`}
                   onClick={() => {
                     sendDataToGTM({
                       'event': 'promoted-store',
                       'event_category': 'warranty-text click',
                       'event_label': `${store.displaystoreurladdress}`,
                       'event_value': index + 1,
                       'event_language': intl.locale,
                     });
                   }}>
                <p
                  className={s.promotedStoresDesktop__warrantyText}>{intl.formatMessage({ id: 'promotedStoresInfo' })}</p>
              </div>
              <div className={`${s.promotedStoresDesktop__rightSide} ${s.promotedStoresDesktop__link}`}>
                <div className={s.promotedStoresDesktop__button} onClick={() => {
                  sendDataToGTM({
                    'event': 'promoted-store',
                    'event_category': 'button click',
                    'event_label': `${store.displaystoreurladdress}`,
                    'event_value': index + 1,
                    'event_language': intl.locale,
                  });
                }}>
                  <Button href={store.productlink}>
                    {intl.formatMessage({ id: 'promotedStoresButton' })}
                  </Button>
                </div>
              </div>
            </div>
          );
        }
      },
    );
  };

  return (
    <>
      {returnPromotedStores()}
    </>
  );
};

export default PromotedStoresDesktop;

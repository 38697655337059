import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { useWindowSize } from '../../hooks/use-window-size';

import { BREAKPOINT, isMobileResolution } from '@helpers/layout.helper';
import { PromotedStore } from '../../types/product.types';

import { Container } from '../container/Container.component';
import { Header } from '../header/Header.component';
import PromotedStoresDesktop from './components/PromotedStoresDesktop.component';
import PromotedStoresMobile from './components/PromotedStoresMobile.component';

import s from './PromotedStores.module.scss';


interface IPromotedStores {
  promotedStores: PromotedStore[];
}

export const PromotedStores = ({ promotedStores }: IPromotedStores) => {
  const intl = useIntl();

  const windowSize = useWindowSize();
  const isMobile = isMobileResolution(windowSize, BREAKPOINT.DESKTOP);
  
  return (
    <Container>
      <div className={s.promotedStores__header} id={'promoted-store-section'}>
        <Header>{intl.formatMessage({ id: 'promotedStoresHeader'})}</Header>
      </div>
      <div className={s.promotedStores}>
        <div className={s.promotedStores__table}>
          { isMobile ? <PromotedStoresMobile promotedStores={promotedStores} /> : <PromotedStoresDesktop promotedStores={promotedStores} />}
        </div>
      </div>
    </Container>
  );
};



import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import OpinionWidget from '@components/customers-opinion-widget/components/OpinionWidget.component';
import { Container } from '@components/container/Container.component';
import { BREAKPOINT, isMobileResolution } from '@helpers/layout.helper';
import { useWindowSize } from '../../../../hooks/use-window-size';
import { CustomersOpinion } from '../../../../types/product.types';

import style from './ProductOpinion.module.scss';

SwiperCore.use([Pagination]);

interface IProductOpinionComponent {
  customersOpinion: CustomersOpinion[];
}

const ProductOpinionComponent = ({customersOpinion}:IProductOpinionComponent) => {
  const windowSize = useWindowSize();
  const isDesktop = isMobileResolution(windowSize, BREAKPOINT.DESKTOP);
  const intl = useIntl();
  
  const slideMaxWidth = customersOpinion.length <=2 ? '500px': '340px'
  
  return (
    <Container>
      <h2 className={style.productOpinionComponent__header}>{intl.formatMessage({ id: 'product__opinion' })}</h2>
    <div className={style.productOpinionComponent}>
      <Swiper
        spaceBetween={20}
        slidesPerView={isDesktop ? 1 : 3}
        speed={800}
        lazy={true}
        pagination={{
          clickable: true,
          renderBullet: (_index: number, className) => {
              return '<span class="' + className + '">' + ' ' + '</span>';
          },
        }}
      >
        {
          customersOpinion.map((customerOpinion,index) => {
            return (
              <SwiperSlide key={index} style={isDesktop ? {height: 'unset', borderRadius:'10px', backgroundColor: 'white', width: '100%'} : {height: 'unset', borderRadius:'10px', backgroundColor: 'white', minWidth: `${slideMaxWidth}`}}>
                <OpinionWidget customerOpinion={customerOpinion}/>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </div>
    </Container>
  )
}

export default ProductOpinionComponent;

import React from 'react';
import sanitizeHtml from 'sanitize-html';

import { Container } from '@components/container/Container.component';

import { Header } from '@components/header/Header.component';

import s from './ProductColotsDescription.module.scss';

interface IProductColorsDescription {
  header: string;
  text: string;
}

const ProductColorsDescription = ({header, text}: IProductColorsDescription) => {

  return (
    <Container>
      <div className={s.productColorsDescription}>
        <Header>{header}</Header>
        <div className={s.productColorsDescription__text}>
          <p className={`${s.productColorsDescription__textStyle} html-styled-link`} dangerouslySetInnerHTML={{
            __html: sanitizeHtml(text, {
              allowedTags: ['strong', 'a', 'br'],
              allowedAttributes: {
                'a': ['href'],
              },
            }),
          }}>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default ProductColorsDescription;

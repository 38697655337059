import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { PromotedStore } from '../../types/product.types';

import s from './components/PromotedStoresMobile.module.scss';
import { polishLanguage } from '@helpers/translation.helper';
import { useIntl } from 'gatsby-plugin-intl';


export const preparePromoteStoresChunks = (items: PromotedStore[], size: number) => {
  const chunks = []
  items = [].concat(...items)

  while (items.length) {
    chunks.push(
      items.splice(0, size)
    )
  }

  return chunks
}

export const renderPromotedStores = (stores: PromotedStore[], sendDataToGTM: any, text: string) => {
  const intl = useIntl();

  return stores.map((store, index)=> {
    return (
      <div className={s.promotedStoresMobile__single} key={index}>
        <a href={store.productlink} rel={'nofollow noopener'} target={'_blank'} onClick={() => {
          sendDataToGTM({
            'event': 'promoted-store',
            'event_category': 'logo click',
            'event_label': `${store.displaystoreurladdress}`,
            'event_value': index+1,
            'event_language': intl.locale,
          })
        }}>
        <GatsbyImage
          className={s.promotedStoresMobile__img}
          image={getImage(store.storelogo.localFile)!}
          alt={store.storelogo.altText}
        />
        </a>
        <a className={s.promotedStoresMobile__button} href={store.productlink} target={'_blank'} rel={'nofollow noopener'}
           onClick={() => {
             sendDataToGTM({
               'event': 'promoted-store',
               'event_category': 'button click',
               'event_label': `${store.displaystoreurladdress}`,
               'event_value': index+1,
               'event_language': intl.locale,
             })
           }}>
          {text}
        </a>
      </div>
    )
  })
}

import React, { useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { Container } from '@components/container/Container.component';
import { Header } from '@components/header/Header.component';

import style from './Product360.module.scss';


interface IProduct360Component {
  folderNumber: number | undefined;
  name: string;
}

const Product360Component = ({ folderNumber,name }: IProduct360Component) => {
  const intl = useIntl();
  
  useEffect(() => {

    const initializeOrbitvu = () => {
        inject_orbitvu('presentation-container', 'https://media.avionaut.com/website-products-360/orbitvu12/', '',{
          ovus_folder: `https://media.avionaut.com/website-products-360/${folderNumber}`,
          content2: 'yes',
          teaser: 'playonerotationslow',
          teaserplay_overlay_color: '0xFFFFFF2C',
          width: 'auto',
          height: 'auto',
          style: 2
        });
    };

    const timer = setInterval(() => {
      if (typeof inject_orbitvu === 'function') {
        clearInterval(timer);
        initializeOrbitvu();
      } else {
        console.error('inject_orbitvu function is not available');
      }
    }, 100);

    return () => clearInterval(timer); 
  }, []);

  return (
    <Container>
      {folderNumber && <div className={style.product360Component}>
        <Header>{`${intl.formatMessage({ id: 'product__360Header' })} ${name}`}</Header>
        <div id={'presentation-container'}/>
      </div>}
    </Container>
  );
};


export default Product360Component;

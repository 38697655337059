import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import sanitizeHtml from 'sanitize-html';

import { Point, ProductPhotoFluid } from 'types';

import { Container } from '../container/Container.component';
import { Header } from '../header/Header.component';

import s from './Construction.module.scss';


interface IConstruction {
  header: string,
  text: string,
  steps: Point[],
  image: ProductPhotoFluid;
}

export const Construction: FC<IConstruction> = ({ header, text, steps, image }) => {
  
  return (
    <Container>
      <div className={s.construction}>
        <Container>
          <Header>{header}</Header>
          <div className={s.construction__text}>
            <p className={`${s.construction__textStyle} html-styled-link`} dangerouslySetInnerHTML={{
              __html: sanitizeHtml(text, {
                allowedTags: ['strong', 'a', 'br'],
                allowedAttributes: {
                  'a': ['href'],
                },
              }),
            }}>
            </p>
          </div>
          <div className={s.construction__content}>
            <div className={s.construction__image}>
              <GatsbyImage image={getImage(image.localFile)!} alt={image.altText}/>
            </div>
            <div className={s.construction__steps}>
              {
                steps.map((step, index) => {
                  return (
                    <div className={s.construction__step} key={index}>
                      <div className={s.construction__stepPlaceholder}>
                        <div className={s.construction__stepRing}>
                          <p className={s.construction__stepNumber}>{index + 1}</p>
                        </div>
                      </div>
                      <div className={s.construction__stepText}>
                        <p className={s.construction__textContent}>{step.value}</p>
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </Container>
      </div>
    </Container>
  )
};

import React from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useIntl } from 'gatsby-plugin-intl';

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import { PromotedStore } from '../../../types/product.types';
import { preparePromoteStoresChunks, renderPromotedStores } from '../PromotedStores.helper';

import s from './PromotedStoresMobile.module.scss';


interface IPromotedStoresMobile {
  promotedStores: PromotedStore[];
}

SwiperCore.use([Pagination]);

const CHUNK_SIZE = 4;

const PromotedStoresMobile = ({promotedStores} : IPromotedStoresMobile) => {
  const intl = useIntl();
  const sendDataToGTM = useGTMDispatch();

  const chunks = preparePromoteStoresChunks(promotedStores, CHUNK_SIZE);
  
  return (
    <div className={s.promotedStoresMobile}>
      <div className={s.promotedStoresMobile__test}>
        <Swiper
          className={s.promotedStoresMobile__swiper}
          spaceBetween={0}
          slidesPerView={1}
          speed={800}
          loop={false}
          lazy={true}
          preloadImages={true}
          updateOnImagesReady={true}
          pagination={{
            clickable: true,
            renderBullet: (_index: number, className) => {
              return '<span class="' + className + '">' + ' ' + '</span>';
            },
          }}
        >
          {chunks && chunks.map((singleChunk, index) => {
            if (singleChunk) {
              return (
                <SwiperSlide key={index}>
                  <div className={s.promotedStoresMobile__grid}>
                    {
                      renderPromotedStores(singleChunk, sendDataToGTM, intl.formatMessage({ id: 'promotedStoresButton' }))
                    }
                  </div>
                </SwiperSlide>
              );
            }
          })}
        </Swiper>
      </div>
    </div>
  )
}

export default PromotedStoresMobile

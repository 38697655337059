import React, { FC } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { IProductTemplate } from './product.types';
import {
  ProductData,
  ProductMajorData,
  ProductMetaData,
  ProductPhotos,
} from '../../types/product.types';

import { getLink } from '@helpers/linking.helper';
import { PageType } from '@constants/pageType.constants';
import {
  getArticleFromKnowledgeBase,
  getStaticArticle,
  convertStringToBoolean,
  ProductParser,
  getProductValue,
} from './product.helper';

import { WhyThis } from '@components/why-this/WhyThis.component';
import { ProductSpecification } from './components/product-specification/ProductSpecification.component';
import { Rotation } from '@components/rotation/Rotation.component';
import { Construction } from '@components/construction/Construction.component';
import { ProductVideo } from './components/product-video/ProductVideo.component';
import FeaturedProducts from '@components/featured-products/FeaturedProducts.component';

import ProductInfoGrid from './components/product-info-grid/ProductInfoGrid.component';
import { StoreSearch } from '@components/store-search/StoreSearch.component';
import ProductColorsDescription from './components/product-colors-description/ProductColorsDescription.component';
import { baseTypes } from '@helpers/isofixCompatibile';
import ProductOpinionComponent from './components/product-opinion/ProductOpinion.component';
import Product360Component from './components/product-360/Product360.component';
import { PromotedStores } from '@components/promoted-stores/PromotedStores.component';

const ProductTemplate: FC<IProductTemplate> = (props) => {
  const {
    data: {
      wpProduct,
      wpFeaturedProduct,
      allWpGuaranteeProduct,
      allWpGuarantee,
      allWpTopBar,
    },
  } = props;

  const intl = useIntl();

  const guaranteeBanner = allWpGuarantee.edges[0].node.guaranteeData;
  const headerText = allWpGuarantee.edges[0].node.guaranteeData.textBanner;

  const metaProduct: ProductMetaData = wpProduct.productMetaData;
  const product: ProductData = wpProduct.productData;

  const isTopBarVisible = allWpTopBar.edges[0].node && convertStringToBoolean[allWpTopBar.edges[0].node.topBarData.istopbarvisible];
  const isIsofixIQ = wpProduct.slug === baseTypes.isofixIQ;
  const isIsofixIQ20C = wpProduct.slug === baseTypes.isofixIQ20C;
  const isIsofixDock2 = wpProduct.slug === baseTypes.isofixDock2;
  const isRwf = convertStringToBoolean[metaProduct.isproductrwf];
  const isCompatibleWithIQBase = convertStringToBoolean[metaProduct.iscompatiblewithiqbase];
  const isCompatibleWithIQBase20C = convertStringToBoolean[metaProduct.iscompatiblewithiqbase20c];
  const isCloudCare = convertStringToBoolean[metaProduct.iscloudcare];
  const isProductIncludedInNewStyleCampaign = convertStringToBoolean[metaProduct.newcolorversionavailable];
  const product360FolderNumber = getProductValue(wpProduct.slug)
  
  const productPhotos: ProductPhotos = {
    featuredPhotos: product.featuredphotos,
    frontPhotos: product.frontphotos,
    sidePhotos: product.sidephotos,
    featuredPhotosTwo: product.featuredphotostwo,
    backPhotos: product.backphotos,
    additionalPhotosOne: product.additionalphotosone,
    additionalPhotosTwo: product.additionalphotostwo,
    additionalPhotosThree: product.additionalphotosthree,
  };

  const productData: ProductMajorData = {
    carSeatName: product.name,
    carSeatNameBold: product.namebolded,
    productTags: product.producttags,
    tags: product.tags,
    colors: product.productavailablecolors,
    certificates: product.certificates,
    points: product.bulleteddescription,
    isPromotedStores: product.promotedstores,
    instruction: product.instructionfile,
    promotedstores: product.promotedstores,
    secondinstruction: product.secondinstructionfile,
    compatibilityList: product.compatibilitylistfile,
    compatibleStrollers: product.compatiblestrollers,
    specialistOpinion: product.specialistsOpinion,
    customersOpinion: product.customersOpinion,
    avionautShopUrl: product.avionautShopUrl,
    isCompatibleWithIQBase,
    isCompatibleWithIQBase20C,
    isCloudCare,
    isProductIncludedInNewStyleCampaign,
    isRwf,
    isIsofixIQ,
    isIsofixIQ20C,
    isIsofixDock2
  };

  const articleLeft = product.whyThisSeatLeftArticle ?
    getArticleFromKnowledgeBase(product.whyThisSeatLeftArticle) :
    getStaticArticle(product.whyThisSeatLeftArticleImage, product.whyThisSeatLeftArticleTitle, product.whyThisSeatLeftArticleLink);

  const articleRight = product.whyThisSeatRightArticle ?
    getArticleFromKnowledgeBase(product.whyThisSeatRightArticle) :
    getStaticArticle(product.whyThisSeatRightArticleImage, product.whyThisSeatRightArticleTitle, product.whyThisSeatRightArticleLink);

  return (
    <>
      <ProductInfoGrid
        product={productData}
        photos={productPhotos}
        isTopBarVisible={isTopBarVisible}
      />
      {
        product.customersOpinion &&
        <ProductOpinionComponent customersOpinion={product.customersOpinion}/>
      }
      <Product360Component folderNumber={product360FolderNumber} name={product.namebolded}/>
      <ProductSpecification
        specification={product.specs}
        header={product.specificationheader}
        product={wpProduct.title}
        allWpGuaranteeProduct={allWpGuaranteeProduct}
      />
      <WhyThis
        header={product.descriptionheader}
        text={product.whythisseattext}
        bigImg={product.whythisseatbiggerphotoleft}
        smallImg={product.whythisseatsmallerphotoright}
        articleLeft={articleLeft}
        articleRight={articleRight}
        product={productData.carSeatNameBold}
        specialistsOpinion={product.specialistsOpinion}
      />
      {/*{(product.sprite && product.innovationheader) &&*/}
      {/*  <Rotation*/}
      {/*    header={product.innovationheader}*/}
      {/*    sprite={product.sprite.localFile.childImageSharp.original.src}*/}
      {/*  />*/}
      {/*}*/}
      <Construction
        header={product.constructionheader}
        text={product.constructiontext}
        steps={product.constructionpoints}
        image={product.constructionphoto}
      />
      {
        product.colorsheader &&
        <ProductColorsDescription header={product.colorsheader} text={product.colorstext}/>
      }
      <ProductVideo
        productVideos={product.productvideos}
        header={product.videoheader}
        sublineTxt={intl.formatMessage({ id: 'product__playVideo' })}/>
      {
        product.promotedstores &&
        <PromotedStores
          promotedStores={product.promotedstores}
        />
      }
      <FeaturedProducts
        productCart
        header={intl.formatMessage({ id: 'featuredProducts__header' })}
        products={wpFeaturedProduct.featuredProducts.products}
      />
      <StoreSearch
        header={intl.formatMessage({ id: 'product__storeSearchHeader' })}
        fieldText={intl.formatMessage({ id: 'product__storeSearchPlaceholder' })}
        shopsURL={getLink(intl.locale, PageType.SHOPS)}
        currentLocale={intl.locale}
        isBackgroundVisible
      />
    </>
  );
};

export default ProductTemplate;

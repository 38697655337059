import React from 'react';
import { graphql, Script } from 'gatsby';

import ProductTemplate from '../../app/product/Product';
import { IProductTemplate, Review } from '../../app/product/product.types';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { injectIntl, useIntl } from 'gatsby-plugin-intl';
import { getLink } from '@helpers/linking.helper';
import { PageType } from '@constants/pageType.constants';
import { ProductData } from '../../types/product.types';
import { ProductThumbnailsHelper } from '@helpers/thumbnails.helper';
import { getLocalizedSlug } from '@helpers/knowledgeBase.helper';

const Product = (props: IProductTemplate) => {
  
  const {
    data: {
      wpProduct,
    }
  } = props;
  
  const intl = useIntl();

  const product: ProductData = wpProduct.productData;
  const slug: string = wpProduct.slug;
  const canonicalLink = 'https://avionaut.com' + `${getLink(intl.locale, PageType.CAR_SEAT)}` + '/' + `${slug}` + '/';
  
  const aggregateRating = () => {
    if(product.customersOpinion) {
      
      const arrayOfRating: number[] = [];
      
      product.customersOpinion.forEach((opinion) => {
        arrayOfRating.push(opinion.rating);
      })
      
      const rating = arrayOfRating.reduce((a,b) => a + b, 0) / arrayOfRating.length;
      
      return rating.toString();
    }
    
    return '5';
  }
  
  const getCustomerOpinion = () => {
    const opinionArray = [];
    
    if(product.customersOpinion) {
      product.customersOpinion.forEach((opinion) => {
        
        opinionArray.push({
          '@type': 'Review',
          'reviewRating': {
            '@type': 'Rating',
            'ratingValue': opinion.rating.toString(),
            'bestRating': '5',
            'worstRating': '4',
          },
          'reviewBody': opinion.opinion,
          'author': {
            '@type': 'Person',
            'name': opinion.client
          }
        })
      })
    }

    return opinionArray;
  }
  
  const customerOpinionArray: Review[] = getCustomerOpinion();
  const averageRating: string = aggregateRating().toString();
  
  const jsonData = {
    '@context': 'https://schema.org/',
    '@graph':
      [
        {
          '@type': 'Product',
          'name': `Avionaut - ${product.namebolded}`,
          'description': product.metadescription,
          'brand': 'Avionaut',
          'reviews': customerOpinionArray,
          'images': ProductThumbnailsHelper[slug],
          'aggregateRating': {
            '@type': 'AggregateRating',
            'ratingValue': averageRating,
            'reviewCount': customerOpinionArray.length === 0 ? 1 : customerOpinionArray.length
          }
        },
        {
          '@type': 'Organization',
          'url': `https://avionaut.com${getLink(intl.locale, PageType.START)}`,
          'logo': 'https://avionaut.com/avionaut.svg'
        }
      ]
  }
  
  return (
    <>
      <Script type="application/ld+json">{JSON.stringify(jsonData)}</Script>
      <Script type="text/javascript" src={'https://media.avionaut.com/website-products-360/orbitvu12/orbitvu.js'} />
      <GatsbySeo
        htmlAttributes={{
          language: intl.locale,
        }}
        languageAlternates={[
          {
            hrefLang: 'x-default',
            href: `https://avionaut.com${getLink(intl.locale, PageType.CAR_SEAT)}/${slug}/`,
          },
          {
            hrefLang: 'en',
            href: `https://avionaut.com${getLink('en', PageType.CAR_SEAT)}/${slug}/`,
          },
          {
            hrefLang: 'pl',
            href: `https://avionaut.com${getLink('pl', PageType.CAR_SEAT)}/${slug}/`,
          },
          {
            hrefLang: 'de',
            href: `https://avionaut.com${getLink('de', PageType.CAR_SEAT)}/${slug}/`,
          },
          {
            hrefLang: 'cz',
            href: `https://avionaut.com${getLink('cz', PageType.CAR_SEAT)}/${slug}/`,
          },
          {
            hrefLang: 'hr',
            href: `https://avionaut.com${getLink('hr', PageType.CAR_SEAT)}/${slug}/`,
          },
          {
            hrefLang: 'es',
            href: `https://avionaut.com${getLink('es', PageType.CAR_SEAT)}/${slug}/`,
          },
          {
            hrefLang: 'fr',
            href: `https://avionaut.com${getLink('fr', PageType.CAR_SEAT)}/${slug}/`,
          },
        ]}
        canonical={canonicalLink}
        title={`Avionaut - ${product.namebolded}`}
        description={product.metadescription}
        metaTags={[
          {
            property: 'thumbnail',
            content: ProductThumbnailsHelper[slug],
          },
          {
            property: 'og:title',
            content: product.metatitle,
          },
          {
            property: 'og:description',
            content: product.metadescription,
          },
          {
            property: 'twitter:title',
            content: product.metatitle,
          },
          {
            property: 'twitter:description',
            content: product.metadescription,
          },
        ]}
      />
      <ProductTemplate data={props.data} path={props.path}/> 
    </>
  );
};

export const WHY_THIS_CARSEAT_ARTICLE = graphql`
  fragment WhyThisCarSeatArticleFieldsArticle on WpArticle {
    id
    title
    slug
    featuredImage {
      node {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, quality: 100)
          }
        }
      }
    }
  }
`;
export const WHY_THIS_CARSEAT_VIDEO = graphql`
  fragment WhyThisCarSeatArticleFieldsVideo on WpVideo {
    id
    title
    slug
    featuredImage {
      node {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, quality: 100)
          }
        }
      }
    }
  }
`;
export const WHY_THIS_CARSEAT_GUIDE = graphql`
  fragment WhyThisCarSeatArticleFieldsGuide on WpGuide {
    id
    title
    slug
    featuredImage {
      node {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, quality: 100)
          }
        }
      }
    }
  }
`;

export const query = graphql`
  query GetSingleProductAndFeaturedProducts($slug: String, $locale: String) {
    wpProduct(slug: {eq: $slug}, locale: {locale: {eq: $locale}}) {
      locale {
        locale
      }
      title
      slug
      productMetaData {
        newcolorversionavailable
        iscompatiblewithiqbase
        iscompatiblewithiqbase20c
        iscloudcare
        isproductvisible
        isproductrwf
      }
      productData {
        specs {
          specsname
          value
        }
        name
        namebolded
        whythisseattext
        compatibilitylistfile {
          link
          title
        }
        compatiblestrollers {
          link
          title
        }
        instructionfile {
          link
          title
        }
        secondinstructionfile {
          link
          title
        }
        metatitle
        metadescription
        bulleteddescription {
          value
        }
        tags
        producttags {
          tagname
        }
        promotedstores {
          productlink
          storeurladdress
          displaystoreurladdress
          storelogo {
            id
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        frontphotos {
          productcolorversion {
            ... on WpColor {
              colorProperties {
                colorhex
                colorname
                colorparameter
              }
            }
          }
          photo {
            id
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        featuredphotos {
          productcolorversion {
            ... on WpColor {
              colorProperties {
                colorhex
                colorname
                colorparameter
              }
            }
          }
          photo {
            id
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        sidephotos {
          productcolorversion {
            ... on WpColor {
              colorProperties {
                colorhex
                colorname
                colorparameter
              }
            }
          }
          photo {
            id
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        backphotos {
          productcolorversion {
            ... on WpColor {
              colorProperties {
                colorhex
                colorname
                colorparameter
              }
            }
          }
          photo {
            id
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        featuredphotostwo {
          productcolorversion {
            ... on WpColor {
              colorProperties {
                colorhex
                colorname
                colorparameter
              }
            }
          }
          photo {
            id
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        additionalphotosone {
          productcolorversion {
            ... on WpColor {
              colorProperties {
                colorhex
                colorname
                colorparameter
              }
            }
          }
          photo {
            id
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        additionalphotostwo {
          productcolorversion {
            ... on WpColor {
              colorProperties {
                colorhex
                colorname
                colorparameter
              }
            }
          }
          photo {
            id
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        additionalphotosthree {
          productcolorversion {
            ... on WpColor {
              colorProperties {
                colorhex
                colorname
                colorparameter
              }
            }
          }
          photo {
            id
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        whythisseatbiggerphotoleft {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, quality: 100)
            }
          }
        }
        whythisseatsmallerphotoright {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, quality: 100)
            }
          }
        }
        whyThisSeatLeftArticle {
          ... WhyThisCarSeatArticleFieldsArticle
          ... WhyThisCarSeatArticleFieldsVideo
          ... WhyThisCarSeatArticleFieldsGuide
        }
        whyThisSeatRightArticle {
          ... WhyThisCarSeatArticleFieldsArticle
          ... WhyThisCarSeatArticleFieldsVideo
          ... WhyThisCarSeatArticleFieldsGuide
        }
        whyThisSeatLeftArticleLink
        whyThisSeatLeftArticleTitle
        whyThisSeatLeftArticleImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, quality: 100)
            }
          }
        }
        whyThisSeatRightArticleLink
        whyThisSeatRightArticleTitle
        whyThisSeatRightArticleImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, quality: 100)
            }
          }
        }
        sprite {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        productvideos {
          productvideocode
          productvideothumbnail {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        constructiontext
        constructionpoints {
          value
        }
        constructionphoto {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, quality: 100)
            }
          }
        }
        certificates {
          photo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        descriptionheader
        specificationheader
        innovationheader
        constructionheader
        videoheader
        colorsheader
        colorstext
        productavailablecolors {
          ... on WpColor {
            id
            colorProperties {
              colorhex
              colorname
              colorparameter
            }
          }
        }
        customersOpinion {
          client
          opinion
          rating
        }
        avionautShopUrl
        specialistsOpinion {
          graphic {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 100)
              }
            }
          }
          opinion
          specialist
        }
      }
    }
    allWpGuaranteeProduct {
      edges {
        node {
          guaranteeProductGroup {
            product {
              ... on WpProduct {
                productMetaData {
                  productname
                }
              }
            }
          }
        }
      }
    }
    wpFeaturedProduct(locale: {locale: {eq: $locale}}, slug: {eq: "sekcja-wyroznionych-produktow"}) {
      locale {
        locale
      }
      featuredProducts {
        products {
          ... on WpProduct {
            productMetaData {
              productname
              thumbnail {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                }
              }
              featuredproductsmeta {
                categoryage
                categoryweight
              }
            }
            productData {
              productavailablecolors {
                ... on WpColor {
                  id
                  colorProperties {
                    colorhex
                    colorname
                    colorparameter
                  }
                }
              }
              tags
            }
            slug
          }
        }
      }
      slug
    }
    allWpGuarantee(filter: {locale: {locale: {eq: $locale}}}) {
      edges {
        node {
          guaranteeData {
            textBanner
            banner {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, quality: 100)
                }
              }
            }
            warrantyArticle {
              ... on WpArticle {
                id
                title
                slug
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, quality: 100)
                      }
                    }
                  }
                }
              }
              ... on WpVideo {
                id
                title
                slug
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, quality: 100)
                      }
                    }
                  }
                }
              }
              ... on WpGuide {
                id
                title
                slug
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, quality: 100)
                      }
                    }
                  }
                }
              }
            }
            warrantyArticleLink
            warrantyArticleHeader
            warrantyArticleImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, quality: 100)
                }
              }
            }
          }
        }
      }
    }
    allWpTopBar(filter: {locale: {locale: {eq: $locale}}}) {
      edges {
        node {
          id
          locale {
            id
            locale
          }
          topBarData {
            buttoncta
            buttontext
            istopbarvisible
            promotext
            promotextmobile
          }
        }
      }
    }
  }
`;

export default Product;
